import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";

export default function Parentlayout() {
  return (
    <>
      <NavBar></NavBar>

      <Outlet></Outlet>
      <Footer></Footer>
    </>
  );
}
