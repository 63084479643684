import React from "react";
import img from "../images/Web-developer.svg";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <>
      <div className="m-auto md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
          data-aos="fade-up"
        >
          {/* Image section */}
          <div className="lg:w-1/2 flex justify-center lg:mx-4">
            <img
              alt="card img"
              className="rounded-t lg:rounded float-right lg:w-3/4"
              src={img}
            />
          </div>

          {/* Text section */}
          <div
            className="w-full lg:w-1/2 px-4 lg:pl-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl text-blue-900 font-extrabold font-ibm-plex-sans my-1 lg:mt-8">
              Innovative Solutions for Financial Empowerment
            </h3>
            <div className="w-full">
              <p className="text-lg lg:text-lg mt-2 text-[#264D82] font-semibold text-justify font-ibm-plex-sans leading-normal lg:leading-snug">
                At Capital IQ, we are dedicated to driving financial success.
                With a deep understanding of the financial landscape, our
                experienced team specializes in crafting bespoke solutions
                tailored to your unique needs.Our commitment to excellence
                ensures that every project we undertake is meticulously
                developed to enhance efficiency and streamline business
                processes. By entrusting us, you gain access to a team of
                skilled professionals who are passionate about delivering
                results. Let us be your partner in financial transformation.
                Together, we can unlock new opportunities, drive growth, and
                propel your business towards a prosperous future. Get in touch
                with us today and discover how our tailored solutions can
                revolutionize your financial journey.
              </p>
              {/* Add more content as needed */}
            </div>

            {/* Link button */}
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full lg:w-auto px-6 py-2 mt-4 lg:my-4 text-lg shadow-xl rounded-2xl group font-ibm-plex-sans"
            >
              Contact us
              <svg
                className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
