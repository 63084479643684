import React from "react";
import NavBar from "./Navbar/NavBar";
import team from "../images/team.jpeg";
import Abiswal from "../images/Abiswal.jpg";
import Abiswal1 from "../images/Abiswal1.jpg";
import SAgarwal from "../images/SAgarwal.jpg";
import SAgarwal1 from "../images/SAgarwal.jpg";
import RGoyal from "../images/RGoyal.jpg";
import RGoyal1 from "../images/RGoyal.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
const Team = () => {
  const [isReadMoreA, setIsReadMoreA] = useState(false);
  const [isReadMoreB, setIsReadMoreB] = useState(false);
  const [isReadMoreC, setIsReadMoreC] = useState(false);

  // Function to toggle the state of "Read More"
  const handleReadMoreClickA = () => {
    setIsReadMoreA(!isReadMoreA);
  };
  const handleReadMoreClickB = () => {
    setIsReadMoreB(!isReadMoreB);
  };
  const handleReadMoreClickC = () => {
    setIsReadMoreC(!isReadMoreC);
  };
  return (
    <div className="grid grid-flow-row">
      <div className="mt-8">
        {/* <section class="text-gray-600 body-font bg-[#f3f4f6] ">
          <div class="container px-5 py-12 mx-auto flex flex-wrap">
            <div class="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
              <img class="object-center w-full h-full" src={team} alt="stats" />
            </div>
            <div class="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10 ml-4">
              <div class="w-full sm:p-4 px-4 mb-6">
                <h2 className="my-2 text-center text-4xl text-blue-900 uppercase font-bold">
                  Our Team
                </h2>
              </div>
            </div>
          </div>
        </section> */}
        <div className="w-full flex items-center justify-center text-white ctb">
          <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
            <p className="text-2xl sm:text-4xl lg:text-6xl font-bold mb-4 text-center lg:text-left">
              OUR TEAM
            </p>
          </div>
        </div>

        <section className="text-gray-600 body-font">
          <div className="container px-28 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/3">
                <div className=" border-2 border-gray-200 border-opacity-60 rounded-3xl overflow-hidden">
                  <img
                    className="main-img w-full rounded-lg"
                    src={RGoyal1}
                    alt="Rajat Goyal"
                  />
                  <div className="p-6">
                    <h1 className="title-font text-xl font-semibold text-gray-900 mb-3 font-ibm-plex-sans">
                      Rajat Goyal
                    </h1>
                    <p className=" mb-3 text-[18px] text-justify font-ibm-plex-sans">
                      {isReadMoreA ? (
                        // Display the full paragraph when "Read More" is clicked
                        <>
                          Rajat is a Qualified Chartered Accountant with over 20
                          years of experience in auditing, reporting, and
                          transaction advisory. He holds a certification from
                          the Institute of Internal Auditors, USA, focusing on
                          risk analysis and process improvement. As a
                          consultant, he advises prominent clients across
                          industries, specializing in investment banking,
                          including IPOs, capital restructuring, and
                          fundraising. Co-founder of financial sector companies
                          under Mufin Finance Group. He's involved in diverse
                          startups and industries as a board advisor. Registered
                          as an independent director, he advocates for
                          sustainable business practices and embodying a
                          leadership ethos of strategic commitment and
                          organizational empowerment.
                        </>
                      ) : (
                        // Display only the first four lines when "Read More" is not clicked
                        <>
                          Rajat is a Qualified Chartered Accountant with over 20
                          years of experience in auditing, reporting, and
                          transaction advisory. He holds a ...
                        </>
                      )}
                    </p>
                    <div className="flex items-center flex-wrap">
                      {/* "Read More" link */}
                      <a
                        className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 cursor-pointer"
                        onClick={handleReadMoreClickA}
                      >
                        {isReadMoreA ? "Read Less" : "Read More"}
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14" />
                          <path d="M12 5l7 7-7 7" />
                        </svg>
                      </a>

                      {/* LinkedIn link */}
                      <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-gray-200">
                        <a
                          href="https://www.linkedin.com/in/rajat-goyal-953b94a7/?originalSubdomain=in"
                          className="text-indigo-500 inline-flex items-center"
                          style={{ position: "relative", zIndex: "1" }}
                        >
                          <svg
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            version="1.1"
                            alt="LinkedIn"
                          >
                            <title>Linkedin</title>
                            <g
                              id="Icon/Social/linkedin-color"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <path
                                d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                                id="Shape"
                                fill="#007BB5"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3">
                <div className=" border-2 border-gray-200 border-opacity-60 rounded-3xl overflow-hidden">
                  <img
                    className="main-img rounded-lg"
                    src={Abiswal1}
                    alt="Akshay Biswal"
                  />
                  <div className="p-6">
                    <h1 className="title-font text-xl font-semibold text-gray-900 mb-3 font-ibm-plex-sans">
                      Akshay Biswal
                    </h1>
                    <p className=" mb-3 text-[18px] text-justify font-ibm-plex-sans">
                      {isReadMoreB ? (
                        // Display the full paragraph when "Read More" is clicked
                        <>
                          Akshay Biswal is a qualified Chartered Accountant with
                          17+ years of experience in debt syndication from
                          Nationalized, Private Banks, and structured Debt. He
                          is also an expert in Due Diligence of companies for
                          investment. He has served many big domestic and listed
                          companies as Chief Financial Officer and in various
                          other capacities in the Finance Department. After
                          gaining rich hands-on experience, he transitioned to
                          consultancy, starting his own CA practice and handling
                          debt syndication, raising up to Rs. 2000 crore as debt
                          for his clients. He is a partner with Capital IQ since
                          inception and has been instrumental in handling
                          clients for the organization for fund raising through
                          debt and equity.
                        </>
                      ) : (
                        // Display only the first four lines when "Read More" is not clicked
                        <>
                          Akshay Biswal is a qualified Chartered Accountant with
                          17+ years of experience in debt syndication from
                          Nationalized, Private Banks,...
                        </>
                      )}
                    </p>
                    <div className="flex items-center flex-wrap">
                      {/* Add the "Read More" link */}
                      <a
                        className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 cursor-pointer"
                        onClick={handleReadMoreClickB}
                      >
                        {isReadMoreB ? "Read Less" : "Read More"}
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14" />
                          <path d="M12 5l7 7-7 7" />
                        </svg>
                      </a>

                      <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-gray-200">
                        <a
                          href="https://www.linkedin.com/in/ca-akshaya-biswal-41a957251"
                          className="text-indigo-500 inline-flex items-center"
                        >
                          <svg
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            version="1.1"
                            alt="LinkedIn"
                          >
                            <title>Linkedin</title>
                            <g
                              id="Icon/Social/linkedin-color"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <path
                                d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                                id="Shape"
                                fill="#007BB5"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3 ">
                <div className=" border-2 border-gray-200 border-opacity-60 rounded-3xl overflow-hidden">
                  <img
                    className="main-img w-full px-2"
                    src={SAgarwal1}
                    alt="Sanket Agarwal"
                  />
                  <div className="p-6">
                    <h1 className="title-font text-xl font-semibold text-gray-900 mb-3 font-ibm-plex-sans">
                      Sanket Agarwal
                    </h1>
                    <p className=" mb-3 text-[18px] text-justify font-ibm-plex-sans">
                      {isReadMoreC ? (
                        // Display the full paragraph when "Read More" is clicked
                        <>
                          Sanket Agarwal, a Qualified Chartered Accountant and
                          London Business School Executive Alumni, boasts 12+
                          years of expertise in Tax Consulting, FDI Planning,
                          and Cross Border Consulting. He's renowned for
                          advising businesses venturing into new markets and
                          investors seeking early growth stage opportunities. As
                          the Founder of CA ON WEB Private Limited, he manages a
                          thriving Tax Advisory firm with 200+ clients and
                          collaborates with Alibaba.com on exporter onboarding.
                          Additionally, he spearheads Ebizzbox Online Private
                          Limited, facilitating cross-border e-commerce with
                          Amazon in India. Moreover, as a Partner in Venture
                          Garage, he plays a pivotal role in raising over $100M
                          USD for emerging companies. Recipient of prestigious
                          awards like Emerging Entrepreneur of the Year 2020 and
                          40 Under 40 Awards from Times Group – 2022.
                        </>
                      ) : (
                        // Display a truncated version when "Read More" is not clicked
                        <>
                          Sanket Agarwal, a Qualified Chartered Accountant and
                          London Business School Executive Alumni, boasts 12+
                          years of expertise in...
                        </>
                      )}
                    </p>
                    <div className="flex items-center flex-wrap">
                      {/* "Read More" link */}
                      <a
                        className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 cursor-pointer"
                        onClick={handleReadMoreClickC}
                      >
                        {isReadMoreC ? "Read Less" : "Read More"}
                        <svg
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12h14" />
                          <path d="M12 5l7 7-7 7" />
                        </svg>
                      </a>

                      {/* LinkedIn link */}
                      <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-gray-200">
                        <a
                          href="https://www.linkedin.com/in/sanketag/"
                          className="text-indigo-500 inline-flex items-center"
                        >
                          <svg
                            width="48px"
                            height="48px"
                            viewBox="0 0 48 48"
                            version="1.1"
                            alt="LinkedIn"
                          >
                            <title>Linkedin</title>
                            <g
                              id="Icon/Social/linkedin-color"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <path
                                d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                                id="Shape"
                                fill="#007BB5"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Team;
