import React from "react";
import NavBar from "./Navbar/NavBar";

const Philosophy = () => {
  return (
    <div className="grid grid-flow-row">
      <NavBar />
      <div className="mt-24"> Philosophy</div>
    </div>
  );
};

export default Philosophy;
