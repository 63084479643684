import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import DemoProduct from "./pages/DemoProduct";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import Award from "./components/Award";
import Team from "./components/Team";
import Philosophy from "./components/Philosophy";
import Parentlayout from "./components/ParentLayout";
import Platformaws from "./pages/Platformaws";
import IPOAdvisory from "./pages/IPOAdvisory";
import PrivateEquity from "./pages/PrivateEquity";
import VentureCapital from "./pages/VentureCapital";
import WealthManagement from "./pages/WealthManagement";
import DueDilligence from "./pages/DueDilligence";
import DebtSyndication from "./pages/DebtSyndication";
import Clients from "./components/Clients";
import Blog from "./pages/Blog";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route element={<Parentlayout></Parentlayout>}>
              <Route path="/" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/award" element={<Award />} />
              <Route path="/philosophy" element={<Philosophy />} />
              <Route path="/team" element={<Team />} />
              <Route path="/Platformaws" element={<Platformaws />} />
              <Route path="/ipoadvisory" element={<IPOAdvisory />} />
              <Route path="/privateequity" element={<PrivateEquity />} />
              <Route path="/venturecapital" element={<VentureCapital />} />
              <Route path="/wealthmanagement" element={<WealthManagement />} />
              <Route path="/duedilligence" element={<DueDilligence />} />
              <Route path="/debtsyndication" element={<DebtSyndication />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/get-demo" element={<DemoProduct />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/blogs" element={<Blog />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
