import React from "react";
import { Link } from "react-router-dom";
import ibank from "../images/ibank.png";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";

// import image1 from "../images/image1.jpeg";
// import image2 from "../images/image2.jpeg";
// import image3 from "../images/image3.jpeg";
// import image4 from "../images/image4.jpeg";
const Services = () => {
  return (
    // <div id="services" className="bg-gray-100 ">
    //   <section data-aos="zoom-in-down">
    //     <div className="">
    //       <h3 className="text-3xl text-blue-900 font-extrabold font-ibm-plex-sans text-center my-1 lg:mt-8">
    //         Services
    //       </h3>

    //       <div className="flex justify-center">
    //         <div className="w-20 border-b-4 border-blue-900"></div>
    //       </div>
    //       <h2 className="mt-4 mx-12 mb-4 text-center text-xl lg:text-2xl font-semibold text-blue-900 font-ibm-plex-sans">
    //         We are deeply committed to the growth and success of our clients.
    //       </h2>
    //     </div>

    //     <div className="px-12" data-aos="fade-down" data-aos-delay="600">
    //       <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 ">
    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={ibank}
    //             />
    //             <h2 className="font-bold my-4 text-xl text-center font-ibm-plex-sans ">
    //               IPO Advisory:- SME/Main Board
    //             </h2>
    //             <p className="text-base  font-medium tracking-normal  font-ibm-plex-sans">
    //               Taking a company public through an Initial Public Offering
    //               (IPO) is a significant milestone in its growth journey. Our
    //               invesment banking services offer comprehensive support for
    //               SMEs and companies aspiring to list on the Main Board.
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/ipoadvisory"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>

    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={img2}
    //             />
    //             <h2 className="font-bold my-4 text-3xl text-center font-ibm-plex-sans">
    //               Private Equity
    //             </h2>
    //             <p className="text-xl font-medium font-ibm-plex-sans ">
    //               Accessing capital through private equity can fuel growth,
    //               strategic initiatives, and expansion plans for businesses
    //               across industries. Our investment banking team specializes in
    //               connecting companies with private equity investors who align
    //               with their objectives and vision.
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/privateequity"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>

    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={img3}
    //             />
    //             <h2 className="font-bold my-4 text-3xl text-center font-ibm-plex-sans ">
    //               Venture Capital
    //             </h2>
    //             <p className="text-xl font-medium font-ibm-plex-sans">
    //               Entrepreneurs and startups often require specialized funding
    //               and strategic guidance to scale their ventures. Our venture
    //               capital services are tailored to meet the unique needs of
    //               early-stage companies seeking capital infusion and mentorship.
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/venturecapital"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>

    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={img4}
    //             />
    //             <h2 className="font-bold my-4 text-3xl text-center font-ibm-plex-sans">
    //               Wealth Management
    //             </h2>
    //             <p className="text-xl font-medium font-ibm-plex-sans">
    //               Managing wealth requires a holistic approach that encompasses
    //               investment advisory, financial planning, risk management, and
    //               legacy planning. Our wealth management services cater to
    //               individuals, families, and institutional clients seeking
    //               personalized solutions to preserve and grow their wealth
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/wealthmanagement"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>
    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={img4}
    //             />
    //             <h2 className="font-bold my-4 text-3xl text-center font-ibm-plex-sans">
    //               Due Dilligence
    //             </h2>
    //             <p className="text-xl font-medium font-ibm-plex-sans">
    //               Accessing debt capital can be instrumental in funding growth
    //               initiatives, working capital requirements, and strategic
    //               acquisitions. Our debt syndication services assist businesses
    //               in structuring and raising debt financing tailored to their
    //               specific needs and objectives.
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/duedilligence"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>
    //         <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
    //           <div className="m-2 text-justify text-sm">
    //             <img
    //               alt="card img"
    //               className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
    //               src={img4}
    //             />
    //             <h2 className="font-bold my-4 text-3xl text-center font-ibm-plex-sans ">
    //               Debt Syndication
    //             </h2>
    //             <p className="text-xl font-medium font-ibm-plex-sans">
    //               As experts in alternative and distressed credit, we understand
    //               that traditional lending criteria may not always align with
    //               the needs of every borrower. That's why we offer a diverse
    //               range of financing solutions designed to meet the unique
    //               circumstances of our clients.
    //             </p>
    //           </div>
    //           <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //             <Link
    //               to="/debtsyndication"
    //               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
    //             >
    //               Learn more
    //               <svg
    //                 className="w-4 h-4 ml-1"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 ></path>
    //               </svg>
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    //   {/* <section>
    //     <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
    //       <div
    //         className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
    //         data-aos="zoom-out"
    //       >
    //         <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
    //           <div className="text-blue-900 mb-4">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="72"
    //               height="72"
    //               viewBox="0 0 24 24"
    //               className="fill-current"
    //             >
    //               <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
    //               <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
    //             </svg>
    //           </div>
    //           <h3
    //             className="text-3xl  text-blue-900
    //                         font-bold"
    //           >
    //             We <span className="font-black">Build</span>
    //           </h3>
    //           <div>
    //             <p className="my-3 text-xl text-gray-600 font-semibold">
    //               With over 10 years experience in software analysis and design
    //               and a deep understanding of the latest IT trends and
    //               solutions, we provide customized recommendations and
    //               strategies to help you improve your operations, reduce costs,
    //               and increase efficiency.
    //             </p>
    //           </div>
    //         </div>
    //         <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
    //           <div className="text-blue-900 mb-4">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="72"
    //               height="72"
    //               viewBox="0 0 24 24"
    //               className="fill-current"
    //             >
    //               <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
    //             </svg>
    //           </div>
    //           <h3 className="text-3xl  text-blue-900 font-bold">
    //             We <span className="font-black">Collaborate</span>
    //           </h3>
    //           <div>
    //             <p className="my-3 text-xl text-gray-600 font-semibold">
    //               We can collaborate with your existing tech team to scale
    //               existing software applications or design customized software
    //               applications that suits your everyday need and simplifies
    //               various processes.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section> */}
    // </div>
    <div id="services" className="bg-gray-100 mb-4 ">
      <section data-aos="zoom-in-down">
        <h2 className=" text-center   uppercase  text-3xl text-blue-900 font-extrabold font-ibm-plex-sans my-1 lg:mt-8">
          services
        </h2>

        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900 font-ibm-plex-sans mb-2">
          We are deeply committed to the growth and success of our clients.
        </h2>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 ">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out object-cover  lg:h-[304px]"
                  src={ibank}
                />

                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans  ">
                  IPO Advisory:- SME/Main Board
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans ">
                  Taking a company public through an Initial Public Offering
                  (IPO) is a significant milestone in its growth journey. Our
                  investment banking services offer comprehensive support for
                  SMEs and companies aspiring to list on the Main Board.
                </p>
              </div>
              <div className="mb-4 pt-0 space-x-0 md:space-x-2 md:mb-8 lg:pt-4">
                <Link
                  to="/ipoadvisory"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans pt-16 mt-4 lg:mt-0 lg:pt-0">
                  Private Equity
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans leading-normal">
                  Accessing capital through private equity can fuel growth,
                  strategic initiatives, and expansion plans for businesses
                  across industries. Our investment banking team specializes in
                  connecting companies with private equity investors who align
                  with their objectives and vision.
                </p>
              </div>
              <div className="mb-4 lg:pt-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/privateequity"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                />
                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans">
                  Venture Capital
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans pt-4">
                  Entrepreneurs and startups often require specialized funding
                  and strategic guidance to scale their ventures. Our venture
                  capital services are tailored to meet the unique needs of
                  early-stage companies seeking capital infusion and mentorship.
                </p>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/venturecapital"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />

                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans">
                  Wealth Management
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans pt-4">
                  Managing wealth requires a holistic approach that encompasses
                  investment advisory, financial planning, risk management, and
                  legacy planning. Our wealth management services cater to
                  individuals, families, and institutional clients seeking
                  personalized solutions to preserve and grow their wealth
                </p>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/wealthmanagement"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />

                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans">
                  Due Dilligence
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans pt-4">
                  Accessing debt capital can be instrumental in funding growth
                  initiatives, working capital requirements, and strategic
                  acquisitions. Our debt syndication services assist businesses
                  in structuring and raising debt financing tailored to their
                  specific needs and objectives.
                </p>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/duedilligence"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />

                <h2 className="font-semibold my-2 text-xl text-center font-ibm-plex-sans">
                  Debt Syndication
                </h2>
                <p className="text-base font-medium font-ibm-plex-sans pt-4">
                  As experts in alternative and distressed credit, we understand
                  that traditional lending criteria may not always align with
                  the needs of every borrower. That's why we offer a diverse
                  range of financing solutions designed to meet the unique
                  circumstances of our clients.
                </p>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/debtsyndication"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="zoom-out"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <div className="text-blue-900 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
              </svg>
            </div>
            <h3
              className="text-3xl  text-blue-900
                          font-bold"
            >
              We <span className="font-black">Build</span>
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                With over 10 years experience in software analysis and design
                and a deep understanding of the latest IT trends and
                solutions, we provide customized recommendations and
                strategies to help you improve your operations, reduce costs,
                and increase efficiency.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <div className="text-blue-900 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
              </svg>
            </div>
            <h3 className="text-3xl  text-blue-900 font-bold">
              We <span className="font-black">Collaborate</span>
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                We can collaborate with your existing tech team to scale
                existing software applications or design customized software
                applications that suits your everyday need and simplifies
                various processes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    </div>
  );
};

// New Services
// const Services = () => {
//   return (
//     <div id="services" className="bg-gray-100 py-12">
//       <section data-aos="zoom-in-down">
//         <div className="my-4 py-4">
//           <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
//             services
//           </h2>

//           <div className="flex justify-center">
//             <div className="w-24 border-b-4 border-blue-900"></div>
//           </div>
//           <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
//             We are deeply committed to the growth and success of our clients.
//           </h2>
//         </div>

//         <div className="px-12" data-aos="fade-down" data-aos-delay="600">
//           <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5 ">
//             <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
//               <div className="m-2 text-justify text-sm">
//                 <img
//                   alt="card img"
//                   className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
//                   src={image1}
//                 />
//                 <h2 className="font-semibold my-4 text-2xl text-center mt-4">
//                   Integrated Investment Bank
//                 </h2>
//                 <p className="text-xl font-medium">
//                   Taking a company public through an Initial Public Offering
//                   (IPO) is a significant milestone in its growth journey. Our
//                   investment banking services offer comprehensive support for
//                   SMEs and companies aspiring to list on the Main Board.
//                 </p>
//               </div>
//               <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                 <Link
//                   to="/ipoadvisory"
//                   className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
//                 >
//                   Learn more
//                   <svg
//                     className="w-4 h-4 ml-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </Link>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
//               <div className="m-2 text-justify text-sm">
//                 <img
//                   alt="card img"
//                   className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
//                   src={image2}
//                 />
//                 <h2 className="font-semibold my-4 text-2xl text-center">
//                   Platform AWS
//                 </h2>
//                 <p className="text-xl font-medium">
//                   Accessing capital through private equity can fuel growth,
//                   strategic initiatives, and expansion plans for businesses
//                   across industries. Our investment banking team specializes in
//                   connecting companies with private equity investors who align
//                   with their objectives and vision.
//                 </p>
//               </div>
//               <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                 <Link
//                   to="/privateequity"
//                   className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
//                 >
//                   Learn more
//                   <svg
//                     className="w-4 h-4 ml-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </Link>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
//               <div className="m-2 text-justify text-sm">
//                 <img
//                   alt="card img"
//                   className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
//                   src={image3}
//                 />
//                 <h2 className="font-semibold my-4 text-2xl text-center ">
//                   Mortgage Lending
//                 </h2>
//                 <p className="text-xl font-medium">
//                   Entrepreneurs and startups often require specialized funding
//                   and strategic guidance to scale their ventures. Our venture
//                   capital services are tailored to meet the unique needs of
//                   early-stage companies seeking capital infusion and mentorship.
//                 </p>
//               </div>
//               <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                 <Link
//                   to="/venturecapital"
//                   className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
//                 >
//                   Learn more
//                   <svg
//                     className="w-4 h-4 ml-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </Link>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
//               <div className="m-2 text-justify text-sm">
//                 <img
//                   alt="card img"
//                   className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
//                   src={image4}
//                 />
//                 <h2 className="font-semibold my-4 text-2xl text-center ">
//                   Alternative and Distressed Credit
//                 </h2>
//                 <p className="text-xl font-medium">
//                   Managing wealth requires a holistic approach that encompasses
//                   investment advisory, financial planning, risk management, and
//                   legacy planning. Our wealth management services cater to
//                   individuals, families, and institutional clients seeking
//                   personalized solutions to preserve and grow their wealth
//                 </p>
//               </div>
//               <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
//                 <Link
//                   to="/wealthmanagement"
//                   className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 "
//                 >
//                   Learn more
//                   <svg
//                     className="w-4 h-4 ml-1"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

export default Services;
