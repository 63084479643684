import React from "react";

const Blog = () => {
  return (
    <>
      <section className="text-gray-600 body-font ">
        <div className=" ">
          <div className="w-full flex items-center justify-center text-white ctb">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
              <p className="text-2xl sm:text-2xl lg:text-4xl font-bold mb-4 text-center lg:text-left font-ibm-plex-sans">
                ELIGIBILITY CRITERIA FOR SME IPO'S
              </p>
            </div>
          </div>
          <div className="flex flex-col text-center w-full my-10">
            <p className="text-xl font-semibold tracking-tight mb-5 justify-self-auto leading-relaxed text-justify mx-10 font-ibm-plex-sans text-blue-900">
              In the vibrant landscape of investment, Initial Public Offerings
              (IPOs) stand as gateways to exciting opportunities for both
              companies and investors alike. While IPOs of large corporations
              often steal the spotlight, there's another realm of IPOs that
              offers unique potential: Small and Medium Enterprises (SMEs).
              These SME IPOs, tailored for smaller companies, bring their own
              set of advantages and considerations. However, before diving into
              this promising market, understanding the eligibility criteria is
              paramount. Let's explore.
            </p>
          </div>
          <div className="lg:w-full w-full mx-auto overflow-auto ">
            <table className="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th className="px-4 py-3 title-font tracking-wider font-bold text-xl rounded-tl rounded-bl bg-[#1e3a8a] text-white justify-center text-center font-ibm-plex-sans">
                    PARAMETER
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-bold text-xl bg-[#1e3a8a] text-white justify-center text-center font-ibm-plex-sans">
                    LISTING CRITERION NSE Emerge
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-bold text-xl bg-[#1e3a8a] text-white justify-center text-center font-ibm-plex-sans">
                    LISTING CRITERION BSE SME
                  </th>
                </tr>
              </thead>

              <tbody className="text-xl">
                <tr>
                  <td className="px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    INCORPORATION
                  </td>
                  <td className="px-4 py-3 font-ibm-plex-sans">
                    The Issuer should be a company incorporated under the
                    Companies Act 1956 / 2013 in India.
                  </td>
                  <td className="px-4 py-3 font-ibm-plex-sans">
                    The Issuer should be a company incorporated under the
                    Companies Act 1956 / 2013 in India.
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    ELIGIBILITY CRITERIA
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The post issue paid up capital of the company (face value)
                    shall not be more than Rs. 25 crore.
                    <br />
                    Net Worth Should be Positive <br />
                    NA
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The post issue paid up capital of the company (face value)
                    shall not be more than Rs. 25 crore.
                    <br /> Networth Should be Atleast Rs. 1 crore for 2
                    preceding full financial years.
                    <br />
                    Net Tangible assets should be Rs 3 crores in last preceding
                    (full) financial years
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    TRACK RECORD
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    Track record of atleast three years of either
                    <br />
                    i. the applicant seeking listing; or
                    <br />
                    ii. the promoters/promoting company, incorporated in or
                    outside India or
                    <br />
                    iii.Proprietary / Partnership firm and subsequently
                    converted into a Company (not in existence as a Company for
                    three years) and approaches the Exchange for listing.
                    <br />
                    ****Promoters mean one or more persons with minimum 3 years
                    of experience in the same line of business and shall be
                    holding at least 20% of the post issue equity share capital
                    individually or severally
                  </td>

                  <td className="border-t-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    Track record of atleast three years of either
                    <br />
                    i. the applicant seeking listing; or
                    <br />
                    NA
                    <br />
                    iii.Proprietary / Partnership firm and subsequently
                    converted into a Company (not in existence as a Company for
                    three years) and approaches the Exchange for listing.
                  </td>
                </tr>

                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    EBITDA
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The company/entity should have operating profit (earnings
                    before interest, depreciation and tax) from operations for
                    atleast any 2 out of 3 financial years preceding the
                    application and its net-worth should be positive.
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The company/ proprietorship concern/ registered firm/ LLP
                    should have operating profit (earnings before interest,
                    depreciation and tax) from operations for 2 out of 3 latest
                    financial years preceding the application date.
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    LEVERAGE RATIO
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    NA
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    Leverage ratio of not more than 3:1. Relaxation may be
                    granted to finance companies.
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    OTHER LISTING CONDITIONS
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The applicant company has not been referred to erstwhile
                    Board for Industrial and Financial Reconstruction (BIFR) or
                    No proceedings have been admitted under Insolvency and
                    Bankruptcy Code against the issuer and Promoting companies.
                    <br />
                    The company has not received any winding up petition
                    admitted by a NCLT / Court.
                    <br />
                    No material regulatory or disciplinary action by a stock
                    exchange or regulatory authority in the past three years
                    against the applicant company.
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    No regulatory action of suspension of trading against the
                    promoter(s) or companies promoted by the promoters by any
                    stock Exchange having nationwide trading terminals.
                    <br />
                    The Promoter(s) or directors shall not be promoter(s) or
                    directors (other than independent directors) of compulsory
                    delisted companies by the Exchange and the applicability of
                    consequences of compulsory delisting is attracted or
                    companies that are suspended from trading on account of
                    non-compliance.
                    <br /> Director should not be disqualified/ debarred by any
                    of the Regulatory Authority.
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    REJECTION COOLING OFF PERIOD
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    The application of the applicant company should not have
                    been rejected by the Exchange in last 6 complete months.
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    NA
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 bg-[#1e3a8a] text-white font-ibm-plex-sans">
                    NAME CHANGE
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    NA
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 font-ibm-plex-sans">
                    In case of name change within the last one year, at least
                    50% of the revenue calculated on a restated and consolidated
                    basis for the preceding 1 full financial year has been
                    earned by it from the activity indicated by its new name.
                    <br />
                    The activity suggesting name should have contributed to at
                    least 50% of the revenue, calculated on a restated and
                    consolidated basis, for the preceding one full financial
                    year
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
