// import React, { useState } from "react";
// import NavBar from "../components/Navbar/NavBar";
// import Footer from "../components/Footer";
// import { useDocTitle } from "../components/CustomHook";
// import { Link } from "react-router-dom";
// import axios from "axios";
// // import emailjs from 'emailjs-com';
// import Notiflix from "notiflix";

// const Contact = () => {
//   useDocTitle("MLD | Molad e Konsult - Send us a message");
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phone, setPhone] = useState("");
//   const [message, setMessage] = useState("");
//   const [errors, setErrors] = useState([]);

//   const clearErrors = () => {
//     setErrors([]);
//   };

//   const clearInput = () => {
//     setFirstName("");
//     setLastName("");
//     setEmail("");
//     setPhone("");
//     setMessage("");
//   };

//   const sendEmail = (e) => {
//     e.preventDefault();
//     document.getElementById("submitBtn").disabled = true;
//     document.getElementById("submitBtn").innerHTML = "Loading...";
//     let fData = new FormData();
//     fData.append("first_name", firstName);
//     fData.append("last_name", lastName);
//     fData.append("email", email);
//     fData.append("phone_number", phone);
//     fData.append("message", message);

//     axios({
//       method: "post",
//       url: process.env.REACT_APP_CONTACT_API,
//       data: fData,
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//       .then(function (response) {
//         document.getElementById("submitBtn").disabled = false;
//         document.getElementById("submitBtn").innerHTML = "send message";
//         clearInput();
//         //handle success
//         Notiflix.Report.success("Success", response.data.message, "Okay");
//       })
//       .catch(function (error) {
//         document.getElementById("submitBtn").disabled = false;
//         document.getElementById("submitBtn").innerHTML = "send message";
//         //handle error
//         const { response } = error;
//         if (response.status === 500) {
//           Notiflix.Report.failure(
//             "An error occurred",
//             response.data.message,
//             "Okay"
//           );
//         }
//         if (response.data.errors !== null) {
//           setErrors(response.data.errors);
//         }
//       });
//   };
//   return (
//     <>
//       <div
//         id="contact"
//         className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
//       >
//         <div
//           className="container mx-auto my-8 px-4 lg:px-20  "
//           data-aos="zoom-in"
//         >
//           <form onSubmit={sendEmail}>
//             <div className="w-full  p-8 my-4 md:px-12 lg:w-2/9 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
//               <div className="flex">
//                 <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
//                   Send us a message
//                 </h1>
//               </div>
//               <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5 ">
//                 <div>
//                   <input
//                     name="first_name"
//                     className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                     type="text"
//                     placeholder="First Name*"
//                     value={firstName}
//                     onChange={(e) => setFirstName(e.target.value)}
//                     onKeyUp={clearErrors}
//                   />
//                   {errors && (
//                     <p className="text-red-500 text-sm">{errors.first_name}</p>
//                   )}
//                 </div>

//                 <div>
//                   <input
//                     name="last_name"
//                     className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                     type="text"
//                     placeholder="Last Name*"
//                     value={lastName}
//                     onChange={(e) => setLastName(e.target.value)}
//                     onKeyUp={clearErrors}
//                   />
//                   {errors && (
//                     <p className="text-red-500 text-sm">{errors.last_name}</p>
//                   )}
//                 </div>

//                 <div>
//                   <input
//                     name="email"
//                     className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                     type="email"
//                     placeholder="Email*"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     onKeyUp={clearErrors}
//                   />
//                   {errors && (
//                     <p className="text-red-500 text-sm">{errors.email}</p>
//                   )}
//                 </div>

//                 <div>
//                   <input
//                     name="phone_number"
//                     className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                     type="number"
//                     placeholder="Phone*"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     onKeyUp={clearErrors}
//                   />
//                   {errors && (
//                     <p className="text-red-500 text-sm">
//                       {errors.phone_number}
//                     </p>
//                   )}
//                 </div>
//               </div>
//               <div className="my-4 ">
//                 <textarea
//                   name="message"
//                   placeholder="Message*"
//                   className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   value={message}
//                   onChange={(e) => setMessage(e.target.value)}
//                   onKeyUp={clearErrors}
//                 ></textarea>
//                 {errors && (
//                   <p className="text-red-500 text-sm">{errors.message}</p>
//                 )}
//               </div>
//               <div className="my-2 w-1/2 lg:w-2/4">
//                 <button
//                   type="submit"
//                   id="submitBtn"
//                   className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full
//                                     focus:outline-none focus:shadow-outline"
//                 >
//                   Send Message
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//         <div
//           className="  lg:w-2/6 px-12 py-24  bg-blue-900 rounded-2xl"
//           data-aos="zoom-in"
//         >
//           <div className="flex flex-col text-white">
//             <div className="flex my-4 w-2/3 lg:w-3/4">
//               <div className="flex flex-col">
//                 <i className="fas fa-map-marker-alt pt-2 pr-2" />
//               </div>
//               <div className="flex flex-col">
//                 <h2 className="text-2xl">Office Address</h2>
//                 <p className="text-gray-400">
//                   H-1/208 Garg Tower Netaji Subhash Place, Delhi-110034
//                 </p>
//               </div>
//             </div>

//             <div className="flex my-4 w-2/3 lg:w-1/2">
//               <div className="flex flex-col">
//                 <i className="fas fa-phone-alt pt-2 pr-2" />
//               </div>

//               <div className="">
//                 <div className="mt-5">
//                   <h2 className="text-2xl">Send an E-mail</h2>
//                   <p className="text-gray-400"> rajat@capital-iq.in</p>
//                 </div>
//                 <div>
//                   <Link
//                     to="https://www.linkedin.com/company/101714003/admin/feed/posts/"
//                     className="bg-white "
//                     aria-label="LinkedIn"
//                   >
//                     <svg
//                       width="36px"
//                       height="36px"
//                       viewBox="0 0 48 48"
//                       version="1.1"
//                     >
//                       <title>Linkedin</title>
//                       <g
//                         id="Icon/Social/linkedin-color"
//                         stroke="none"
//                         stroke-width="1"
//                         fill="none"
//                         fill-rule="evenodd"
//                       >
//                         <path
//                           d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
//                           id="Shape"
//                           fill="#007BB5"
//                         ></path>
//                       </g>
//                     </svg>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Contact;

import React, { useRef } from "react";
import { MailIcon } from "@heroicons/react/outline";

const Contact = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    /* 
    Do something here !
    */
  };

  return (
    <div className="relative mx-auto w-full max-w-7xl bg-white text-gray-700">
      <div className="grid grid-cols-2">
        {/* :MAP CONTAINER */}
        <div className="order-1 col-span-full">
          <iframe
            src="https://maps.google.com/maps?q=H-1%2F208+Garg+Tower+Netaji+Subhash+Place+Delhi-110034+%2C+India&t=&z=13&ie=UTF8&iwloc=&output=embed"
            title="map"
            scrolling="no"
            frameborder="0"
            width="100%"
            height="300px"
            className=""
            loading="lazy"
          />
        </div>

        {/* :CONTACT FORM CONTAINER */}
        <div className="order-3 md:order-2 col-span-full md:col-span-1 py-5 md:py-10 px-6">
          <form
            action=""
            className="mx-auto max-w-xl space-y-4"
            onSubmit={handleSubmit}
          >
            {/* ::Name Input */}
            <div>
              {/* :::label */}
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              {/* :::input */}
              <input
                ref={nameRef}
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                className="form-input w-full block shadow-sm rounded p-2 border-gray-300 bg-gray-100 text-base placeholder-gray-300 focus:border-green-400 focus:ring-1 focus:ring-green-400"
              />
            </div>
            {/* ::Email Input */}
            <div>
              {/* :::label */}
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              {/* :::input */}
              <input
                ref={emailRef}
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                className="form-input w-full block shadow-sm rounded  p-2 border-gray-300 bg-gray-100 text-base placeholder-gray-300 focus:border-green-400 focus:ring-1 focus:ring-green-400"
              />
            </div>
            {/* ::Message Input */}
            <div className="col-span-full">
              {/* :::label */}
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              {/* :::input */}
              <textarea
                ref={messageRef}
                name="message"
                id="message"
                cols="30"
                rows="4"
                placeholder="How can we help?"
                className="form-textarea resize-none w-full shadow-sm rounded p-2 border-gray-300 bg-gray-100 placeholder-gray-300 focus:border-green-400 focus:ring-green-400"
              ></textarea>
            </div>
            {/* ::Submit Button */}
            <div>
              <button
                type="submit"
                className="py-2 px-6 rounded bg-blue-900 text-base text-white font-semibold uppercase hover:bg-blue-500"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>

        {/* :CONTACT INFOS CONTAINER */}
        <div className="order-2 md:order-3 col-span-full md:col-span-1 py-5 md:py-10 px-6">
          <div className="mx-auto max-w-xl flex flex-col space-y-5">
            {/* ::Title Contact Us */}
            <h2 className="text-4xl font-oswald uppercase text-[#264d82]">
              Contact us
            </h2>
            {/* ::Text */}
            <p className="text-sm text-gray-500 font-semibold text-justify font-ibm-plex-sans ">
              We welcome your communication! Whether you have a question, need
              assistance, or simply want to connect, don't hesitate to reach
              out. Our team is here to help. Your feedback matters to us, and we
              look forward to hearing from you soon
            </p>
            {/* ::Email contact */}
            <a
              href="#mail"
              className="inline-flex items-center text-sm text-blue-400 font-semibold hover:text-blue-500"
            >
              <MailIcon className="mr-2 w-5 text-gray-400" />
              rajat@capital-iq.in
            </a>
            {/* ::Address */}
            <p className="text-sm text-gray-500 leading-6 font-semibold text-justify font-ibm-plex-sans ">
              H-1/208 Garg Tower Netaji Subhash Place
              <br /> Delhi-110034 , India
            </p>
            {/* ::Socials */}
            <div className="flex items-center">
              {/* :FACEBOOK */}
              <a
                href="#facebook"
                className="m-1.5 w-10 h-10 inline-flex justify-center items-center shadow-sm rounded-full filter hover:brightness-125"
              >
                {/* ::Linkedin svg */}
                <svg
                  width="36px"
                  height="36px"
                  viewBox="0 0 48 48"
                  version="1.1"
                >
                  <title>Linkedin</title>
                  <g
                    id="Icon/Social/linkedin-color"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                      id="Shape"
                      fill="#007BB5"
                    ></path>
                  </g>
                </svg>
              </a>
              {/* :Instagram */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
