import React from "react";
import Lottie from "lottie-react";
import A6 from "../lottie/A6.json";
import NavBar from "../components/Navbar/NavBar";
import { Link } from "react-router-dom";
import aboutus from "../images/aboutus.gif";
import management from "../images/management.jpg";
import award from "../images/award.jpg";
import Philosophy from "../images/philosophy.png";
import Newsletter from "../components/NewsLetter";

const DueDilligence = () => {
  return (
    // <div className="hero mt-16" id="hero">
    //   <div
    //     className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
    //     data-aos="zoom-in"
    //   >
    //     <div
    //       id="hero"
    //       className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
    //     >
    //       <div
    //         className="lg:w-1/2 flex flex-col justify-center"
    //         data-aos="zoom-in"
    //         data-aos-delay="200"
    //       >
    //         <h1 className="mb-5 mt-4 md:text-5xl text-3xl font-bold text-blue-900">
    //           {/* We build digital solutions to help businesses scale */}
    //           DUE DILLIGENCE
    //         </h1>
    //         <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500 justify-self-auto leading-relaxed text-justify">
    //           Thorough due diligence is essential for informed decision-making
    //           in mergers, acquisitions, and strategic partnerships. Our due
    //           diligence services provide comprehensive assessments of target
    //           companies, identifying risks, opportunities, and value drivers
    //           that impact transaction outcomes. Leveraging our multidisciplinary
    //           expertise, we conduct financial, legal, operational, and
    //           commercial due diligence to uncover insights crucial for
    //           mitigating risks and optimizing deal structures. Our rigorous
    //           approach helps clients make informed investment decisions with
    //           confidence and clarity.
    //         </div>
    //         <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
    //           <Link
    //             to="/contact"
    //             className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
    //           >
    //             Learn more
    //             <svg
    //               className="w-4 h-4 ml-1"
    //               xmlns="http://www.w3.org/2000/svg"
    //               viewBox="0 0 20 20"
    //               fill="currentColor"
    //             >
    //               <path
    //                 fillRule="evenodd"
    //                 d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
    //                 clipRule="evenodd"
    //               ></path>
    //             </svg>
    //           </Link>
    //         </div>
    //       </div>
    //       <div className="lottie-container lg:w-1/2 flex flex-col justify-center">
    //         <Lottie
    //           animationData={A6}
    //           loop={true}
    //           style={{
    //             height: "80%",
    //             position: "relative",
    //             display: "flex",
    //             justifyContent: "center",
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="hero " id="hero">
        <div
          className="m-auto overflow-hidden mx-4 lg:mt-0 md:p-6 h-5/6"
          data-aos="zoom-in "
        >
          <div
            id="hero"
            className="flex flex-col lg:flex-row justify-between text-center lg:text-left"
          >
            <div
              className="lg:w-1/2 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1 className="mb-5  text-2xl lg:text-4xl font-bold text-blue-900 font-ibm-plex-sans">
                Due Dilligence
              </h1>
              <div className="text-base lg:text-base  text-[#264D82] font-semibold text-justify font-ibm-plex-sans leading-normal lg:leading-snug">
                Thorough due diligence is essential for informed decision-making
                in mergers, acquisitions, and strategic partnerships. Our due
                diligence services provide comprehensive assessments of target
                companies, identifying risks, opportunities, and value drivers
                that impact transaction outcomes. Leveraging our
                multidisciplinary expertise, we conduct financial, legal,
                operational, and commercial due diligence to uncover insights
                crucial for mitigating risks and optimizing deal structures. Our
                rigorous approach helps clients make informed investment
                decisions with confidence and clarity.
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/contact"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
                {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                          Get Started
                          <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                      </Link> */}
                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                          Learn More
                          <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                      </a> */}
              </div>
            </div>
            <div
              className="flex lg:justify-start ml-10 rounded-lg w-full lg:w-1/3"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              {/* <img
                alt="card img"
                className="rounded-t float-right duration-1000  h-full"
                src={aboutus}
              /> */}

              <Lottie
                animationData={A6}
                loop={true}
                style={{
                  height: "80%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
        </div>
        {/* Signup image */}
        {/* <Newsletter /> */}
        {/* Award Recoginisation, Philosophy and Journery */}
        {/* <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3">
              <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-center"
                  src={award}
                  alt="blog"
                />
                <div class="p-6">
                  <h1 class="title-font text-lg font-bold text-gray-900 mb-3">
                    Award and Recognisation
                  </h1>
                  <p class="leading-relaxed mb-3">
                    Photo booth fam kinfolk cold-pressed sriracha leggings
                    jianbing microdosing tousled waistcoat.
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <a
                      href="/award"
                      class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                    >
                      Learn More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-center"
                  src={Philosophy}
                  alt="blog"
                />
                <div class="p-6">
                  <h1 class="title-font text-lg font-bold text-gray-900 mb-3">
                    Our Philosophy
                  </h1>
                  <p class="leading-relaxed mb-3">
                    Photo booth fam kinfolk cold-pressed sriracha leggings
                    jianbing microdosing tousled waistcoat.
                  </p>
                  <div class="flex items-center flex-wrap">
                    <a
                      href="/philosophy"
                      class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                    >
                      Learn More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  class="lg:h-48 md:h-36 w-full object-cover object-center"
                  src={management}
                  alt="blog"
                />
                <div class="p-6">
                  <h1 class="title-font text-lg font-bold text-gray-900 mb-3">
                    Our Management
                  </h1>
                  <p class="leading-relaxed mb-3">
                    Photo booth fam kinfolk cold-pressed sriracha leggings
                    jianbing microdosing tousled waistcoat.
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <a
                      href="/team"
                      class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                    >
                      Learn More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </div>
    </>
  );
};

export default DueDilligence;
