import React from "react";

const Platformaws = () => {
  return (
    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
      <div
        className="flex flex-col-reverse lg:flex-row py-12 justify-between lg:text-left"
        data-aos="fade-up"
      >
        <div>Platformaws</div>
      </div>
    </div>
  );
};

export default Platformaws;
