// import React, { useState, useEffect } from "react";
// import NavLinks from "../Navbar/NavLinks";
// import { HashLink } from "react-router-hash-link";
// import CallIcon from "@mui/icons-material/Call";
// import CapitalIQ from "../../images/CapitalIQ.png";
// const NavBar = () => {
//   const [top, setTop] = useState(!window.scrollY);
//   const [isOpen, setisOpen] = React.useState(false);
//   function handleClick() {
//     setisOpen(!isOpen);
//   }

//   useEffect(() => {
//     const scrollHandler = () => {
//       window.pageYOffset > 10 ? setTop(false) : setTop(true);
//     };
//     window.addEventListener("scroll", scrollHandler);
//     return () => window.removeEventListener("scroll", scrollHandler);
//   }, [top]);

//   return (
//     <nav
//       className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
//         !top && "bg-white shadow-lg"
//       }`}
//     >
//       {/* <div className="gap-2 grid grid-flow-col mt-4 justify-end">
//         <input
//           type="text"
//           placeholder="Search..."
//           className="px-2 py-1 border border-gray-300 rounded-md"
//         />
//         <CallIcon className="mt-2 text-[#a57a5f] " />
//         <h1 className="mr-4 ml-2 cursor-pointer text-[#a57a5f] py-1.5 font-bold gap-2">
//           0928083085953
//         </h1>
//       </div> */}
//       <div className="flex flex-row justify-between items-center py-2">
//         <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
//           <HashLink smooth to="/#hero">
//             <img className="w-40 h-20" src={CapitalIQ} alt="Capital IQ Logo" />
//           </HashLink>
//         </div>
//         <div className="group flex flex-col items-center">
//           <button
//             className="p-2 rounded-lg lg:hidden text-blue-900"
//             onClick={handleClick}
//           >
//             <svg
//               className="h-6 w-6 fill-current"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//             >
//               {isOpen && (
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
//                 />
//               )}
//               {!isOpen && (
//                 <path
//                   fillRule="evenodd"
//                   d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
//                 />
//               )}
//             </svg>
//           </button>
//           <div className="hidden space-x-6 lg:inline-block p-5">
//             <NavLinks />
//           </div>

//           <div
//             className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white lg:hidden shadow-xl top-14 ${
//               isOpen ? "block" : "hidden"
//             } `}
//           >
//             <div className="flex flex-col space-y-6">
//               <NavLinks />
//             </div>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default NavBar;
// import React, { useState } from "react";
// import CapitalIQ from "../../images/ciq.png";
// import "./style.css";
// function NavBar() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <header>
//       <div className="container mb-12">
//         <nav className={isMenuOpen ? "active" : ""}>
//           <div className="menu-icons" onClick={toggleMenu}>
//             <i className="fas fa-bars"></i>
//             <i className="fas fa-times"></i>
//           </div>
//           {/* <div className="mr-16">
//             <img className="w-44 h-24" src={CapitalIQ} alt="Capital IQ Logo" />
//           </div> */}

//           <a href="/" className="logo">
//             CAPITALIQ
//           </a>
//           <ul class="nav-list">
//             <li>
//               <a href="/">Home</a>
//             </li>
//             <li>
//               <a href="#">
//                 Who We Are <i class="fas fa-caret-down"></i>
//               </a>
//               <ul class="sub-menu">
//                 <li>
//                   <a href="/aboutus">About Us</a>
//                 </li>
//                 {/* <li>
//                   <a href="/philosophy">
//                     Our philosophy
//                     <i class="fas fa-caret-down"></i>
//                   </a>
//                   <ul class="sub-menu">
//                     <li>
//                       <a href="#">Cara Cara</a>
//                     </li>
//                     <li>
//                       <a href="#">Tangerine</a>
//                     </li>
//                     <li>
//                       <a href="#">
//                         Others
//                         <i class="fas fa-caret-down"></i>
//                       </a>
//                       <ul class="sub-menu">
//                         <li>
//                           <a href="#">Lima</a>
//                         </li>
//                         <li>
//                           <a href="#">Seville</a>
//                         </li>
//                         <li>
//                           <a href="#">Lime</a>
//                         </li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </li> */}
//                 <li>
//                   <a href="/team">Our Management</a>
//                 </li>
//                 {/* <li>
//                   <a href="/aboutus">Our Journey</a>
//                 </li> */}
//                 <li>
//                   <a href="/clients">Our Clients</a>
//                 </li>
//                 {/* <li>
//                   <a href="/award">Awards & Recognitions</a>
//                 </li> */}
//               </ul>
//             </li>
//             <li>
//               <a href="#">Our Services</a>
//               <ul class="sub-menu">
//                 <li>
//                   <a href="/ipoadvisory">IPO Advisory</a>
//                 </li>
//                 <li>
//                   <a href="/privateequity">
//                     PRIVATE EQUITY
//                     <i class="fas fa-caret-down"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="/venturecapital">
//                     Venture Capital
//                     <i class="fas fa-caret-down"></i>
//                   </a>
//                 </li>

//                 <li>
//                   <a href="/wealthmanagement">Wealth Management</a>
//                 </li>
//                 <li>
//                   <a href="/duedilligence">Due Dilligence</a>
//                 </li>
//                 <li>
//                   <a href="/debtsyndication">Debt Syndication</a>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <a href="#">
//                 Media
//                 <i class="fas fa-caret-down"></i>
//               </a>
//               <ul class="sub-menu">
//                 <li>
//                   <a href="#">Gallery</a>
//                 </li>
//                 {/* <li>
//                   <a href="#">
//                     Blog
//                     <i class="fas fa-caret-down"></i>
//                   </a>
//                   <ul class="sub-menu">
//                     <li>
//                       <a href="#">For Health</a>
//                     </li>
//                     <li>
//                       <a href="#">Sweet Oranges</a>
//                     </li>
//                     <li>
//                       <a href="#">Bitter Oranges</a>
//                     </li>
//                   </ul>
//                 </li> */}
//                 <li>
//                   <a href="blogs">Blog</a>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <a href="/contact">Contact</a>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </header>
//   );
// }

// export default NavBar;

// new

// import React, { useState } from "react";
// import Navbg from "../../images/navbg.jpeg";
// const Navbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isWhoDropdownOpen, setIsWhoDropdownOpen] = useState(false);
//   const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);
//   const [isMediaDropdownOpen, setIsMediaDropdownOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false);
//   };

//   const toggleDropdown = () => {
//     setIsWhoDropdownOpen(!isWhoDropdownOpen);
//   };
//   const toggleServiceDropdown = () => {
//     setIsServiceDropdownOpen(!isServiceDropdownOpen);
//   };
//   const toggleMediaDropdown = () => {
//     setIsMediaDropdownOpen(!isMediaDropdownOpen);
//   };
//   const closeDropdown = () => {
//     setIsWhoDropdownOpen(false);
//     setIsServiceDropdownOpen(false);
//     setIsMediaDropdownOpen(false);
//   };
//   return (
//     <nav className="bg-white p-4">
//       <div className="container mx-auto flex justify-between items-center">
//         <div className="flex items-center">
//           {/* <img src="/logo.svg" alt="Logo" className="h-16 mr-2" /> */}
//           <span className="text-4xl  mt-4  ml-8 font-semibold text-[#264D82] font-ibm-plex-sans">
//             CAPITALIQ
//           </span>
//         </div>

//         <button
//           className="text-[#264D82] block focus:outline-none"
//           onClick={toggleMenu}
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-10 w-10"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M4 6h16M4 12h16m-7 6h7"
//             />
//           </svg>
//         </button>
//       </div>

//       {isMenuOpen && (
//         <div
//           className="fixed inset-0 z-50"
//           style={{
//             backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), url(${Navbg})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         >
//           <div className="container mx-auto flex justify-between items-start p-5 capitalize ">
//             <ul className="text-white text-3xl leading-10 p-5">
//               <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                 <a href="/">Home</a>
//               </li>
//               <li
//                 className="py-2 font-ibm-plex-sans text-[28px] font-[300px]"
//                 onMouseEnter={toggleDropdown}
//                 onMouseLeave={closeDropdown}
//               >
//                 <a href="#" className="text-white">
//                   Who we Are
//                 </a>
//                 {isWhoDropdownOpen && (
//                   <ul className="absolute bg-gray-700 text-white py-2 left-48">
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/aboutus" className="block px-4 py-2">
//                         About Us
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/team" className="block px-4 py-2">
//                         Our Management
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/clients" className="block px-4 py-2">
//                         Our Client
//                       </a>
//                     </li>
//                   </ul>
//                 )}
//               </li>
//               <li
//                 className="py-2 font-ibm-plex-sans text-[28px] font-[300px]"
//                 onMouseEnter={toggleServiceDropdown}
//                 onMouseLeave={closeDropdown}
//               >
//                 <a href="#" className="text-white">
//                   Our Services
//                 </a>
//                 {isServiceDropdownOpen && (
//                   <ul className="absolute bg-gray-700 text-white py-2">
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/ipoadvisory" className="block px-4 py-2">
//                         Ipo advisory
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/privateequity" className="block px-4 py-2">
//                         Private equity{" "}
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/venturecapital" className="block px-4 py-2">
//                         Venture Capital{" "}
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/wealthmanagement" className="block px-4 py-2">
//                         Wealth Management{" "}
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/duedilligence" className="block px-4 py-2">
//                         Due Dilligence{" "}
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/debtsyndication" className="block px-4 py-2">
//                         Debt Syndication{" "}
//                       </a>
//                     </li>
//                   </ul>
//                 )}
//               </li>
//               <li
//                 className="py-2 font-ibm-plex-sans text-[28px] font-[300px]"
//                 onMouseEnter={toggleMediaDropdown}
//                 onMouseLeave={closeDropdown}
//               >
//                 <a href="#" className="text-white">
//                   Media
//                 </a>
//                 {isMediaDropdownOpen && (
//                   <ul className="absolute bg-gray-700 text-white py-2">
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="#" className="block px-4 py-2">
//                         Gallery
//                       </a>
//                     </li>
//                     <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                       <a href="/blog" className="block px-4 py-2">
//                         Blog
//                       </a>
//                     </li>
//                   </ul>
//                 )}
//               </li>
//               <li className="py-2 font-ibm-plex-sans text-[28px] font-[300px]">
//                 <a href="/contact">Contact</a>
//               </li>
//             </ul>
//             <button
//               className="text-white block focus:outline-none pl-2 pt-2"
//               onClick={closeMenu}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-10 w-10"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//       )}
//     </nav>
//   );
// };

// export default Navbar;
import React, { useState } from "react";
import "./nav.css";
import Navbg from "../../images/navbg.jpeg";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWhoDropdownOpen, setIsWhoDropdownOpen] = useState(false);
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);
  const [isMediaDropdownOpen, setIsMediaDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleDropdown = (dropdown) => {
    if (dropdown === "who") {
      setIsWhoDropdownOpen(!isWhoDropdownOpen);
      setIsServiceDropdownOpen(false);
      setIsMediaDropdownOpen(false);
    } else if (dropdown === "service") {
      setIsServiceDropdownOpen(!isServiceDropdownOpen);
      setIsWhoDropdownOpen(false);
      setIsMediaDropdownOpen(false);
    } else if (dropdown === "media") {
      setIsMediaDropdownOpen(!isMediaDropdownOpen);
      setIsWhoDropdownOpen(false);
      setIsServiceDropdownOpen(false);
    }
  };

  return (
    <nav className="bg-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          {/* <img src="/logo.svg" alt="Logo" className="h-16 mr-2" /> */}
          <span className="text-4xl  mt-4  ml-8 font-semibold text-[#264D82] font-ibm-plex-sans">
            <a href="/" className="text-blue-900">
              CAPITALIQ
            </a>
          </span>
        </div>

        <button
          className="text-[#264D82] block focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {isMenuOpen && (
        <div
          className="fixed inset-0 z-50"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), url(${Navbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto flex justify-between items-start p-5 capitalize">
            <ul className="text-white text-3xl leading-10 p-5">
              <a href="/">
                <li className="py-2 hover-actions">Home</li>
              </a>

              <li className="py-2" onClick={() => toggleDropdown("who")}>
                <a href="#" className="text-white hover-actions">
                  Who we Are
                </a>
                {isWhoDropdownOpen && (
                  <ul className=" submenu absolute bg-gray-700 text-white py-2 left-60 top-20">
                    <li>
                      <a
                        href="/aboutus"
                        className="block px-4 py-2 hover-actions "
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/team"
                        className="block px-4 py-2 hover-actions "
                      >
                        Our Management{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/clients"
                        className="block px-4 py-2 hover-actions "
                      >
                        Our Client
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className="py-2" onClick={() => toggleDropdown("service")}>
                <a href="#" className="text-white hover-actions">
                  Our Services
                </a>
                {isServiceDropdownOpen && (
                  <ul className="submenu absolute bg-gray-700 text-white py-2 left-60 top-20">
                    <li>
                      <a
                        href="/ipoadvisory"
                        className="block px-4 py-2 hover-actions"
                      >
                        Ipo advisory
                      </a>
                    </li>
                    <li>
                      <a
                        href="/privateequity"
                        className="block px-4 py-2 hover-actions "
                      >
                        Private equity{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/venturecapital"
                        className="block px-4 py-2 hover-actions"
                      >
                        Venture Capital{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/wealthmanagement"
                        className="block px-4 py-2 hover-actions"
                      >
                        Wealth Management{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="duedilligence"
                        className="block px-4 py-2 hover-actions"
                      >
                        Due Dilligence{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="debtsyndication"
                        className="block px-4 py-2 hover-actions"
                      >
                        Debt Syndication{" "}
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className="py-2" onClick={() => toggleDropdown("media")}>
                <a href="#" className="text-white hover-actions">
                  Media
                </a>
                {isMediaDropdownOpen && (
                  <ul className="submenu absolute bg-gray-700 text-white py-2 left-60 top-20">
                    <li>
                      <a href="#" className="block px-4 py-2 hover-actions">
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blogs"
                        className="block px-4 py-2 hover-actions"
                      >
                        Blog
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <a href="/contact" className="block  py-2 hover-actions">
                  Contact
                </a>
              </li>
            </ul>
            <button
              className="text-white block focus:outline-none pl-2 pt-2"
              onClick={closeMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
